<section class="flex-container column">
  <div class="hero-container">
    <div class="container flex-container column align-center">
      <h1 class="main-title">Your PDF’s tools with <br> a single click </h1>

      <p class="main-subtitle-2">You can access every feature for free, and everything is completely intuitive!</p>

    </div>
  </div>
  <div class="tools-available">


    <div class=" top-blocks">
      <a (click)="pushEvent('merge')" href="/merge" [routerLink]="['/merge']" class="main-block">


        <img src="../../../assets/images/merge.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            Merge Files
          </p>
          <p class="text-block-main__subtitle">
            Combines multiple files into one for easier management and collaboration.
          </p>
        </div>
      </a>
      <a (click)="pushEvent('convert')" href="/convert" [routerLink]="['/convert']" class="main-block">


        <img src="../../../assets/images/convert-to-word.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            PDF to Word
          </p>
          <p class="text-block-main__subtitle">
            Converts PDFs into editable Word files for easy modification.
          </p>
        </div>
      </a>
      <a (click)="pushEvent('convert')" href="/convert" [routerLink]="['/convert']" class="main-block">


        <img src="../../../assets/images/convert-to-pdf.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            Word to PDF
          </p>
          <p class="text-block-main__subtitle">
            Converts Words into editable PDF files for easy modification.
          </p>
        </div>
      </a>

    </div>
    <div class=" top-blocks">
      <a (click)="pushEvent('convert')" href="/convert" [routerLink]="['/convert']" class="main-block">

        <img src="../../../assets/images/convert-to-pp.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            PDF to Power Point
          </p>
          <p class="text-block-main__subtitle">
            Converts PDFs into editable PowerPoint slides.
          </p>
        </div>
      </a>
      <a (click)="pushEvent('compress')" href="/compress" [routerLink]="['/compress']" class="main-block">


        <img src="../../../assets/images/compress.png" alt="">
        <div class="text-block-main">
          <p class="text-block-main__title">
            Compress PDF
          </p>
          <p class="text-block-main__subtitle">
            Reduces PDF file size for easier sharing and storage.
          </p>
        </div>
      </a>
     

    </div>
  </div>
  <div class="advantages">
    <div class="advantage-container">
      <img src="../../../assets/images/adv-1.png" alt="">
      <div class="advantage-container-text">
        <p class="advantage-container__title">Protected Files!</p>
        <p class="advantage-container__subtitle">If you're concerned about your safety, you'll be reassured. Your files
          are entirely secure when you utilize of Just Convert Files application.</p>
      </div>
    </div>
    <div class="advantage-container reverse">
      <img src="../../../assets/images/adv-2.png" alt="">
      <div class="advantage-container-text">
        <p class="advantage-container__title">Free for everyone</p>
        <p class="advantage-container__subtitle">Our online file tool is more than just a converter. it's your one-stop
          solution for editing any file type, and it's all free of charge.</p>
      </div>
    </div>
    <div class="advantage-container">
      <img src="../../../assets/images/adv-3.png" alt="">
      <div class="advantage-container-text">
        <p class="advantage-container__title">Multi features</p>
        <p class="advantage-container__subtitle">You can choose the conversion method after dragging & dropping your
          file into one document or uploading it from your computer.</p>
      </div>
    </div>

  </div>
  <div class="in-touch">
    <p class="in-touch-heading">
      Let’s Get in Touch
    </p>
    <p class="in-touch-heading-2">
      We’re here to help you make the most of Just Convert Files
    </p>
    <a class="in-touch-email" href="mailto:support@candytechltd.com">
      support@candytechltd.com
    </a>
  </div>
</section>