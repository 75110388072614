import { Component, OnInit } from '@angular/core';
import {  Route, Router } from '@angular/router';
import { GTMService } from '@app/services/GTM.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  constructor(public router: Router, public gtmService: GTMService) { }

  ngOnInit(): void {
  }

  pushEvent(event) {
    this.gtmService.pushEvent(event);
  }

}
