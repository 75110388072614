import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GTMService {
  
  pushEvent(action) {
    const eventData = {
      'event': 'ga_event',
      'eventCategory': 'user_click',
      'eventAction': action,
      'eventLabel': 'pronto'
    }
    //@ts-ignore
    if (eventData && typeof dataLayer !== 'undefined') dataLayer.push(eventData);
  }

 
}