<header [ngClass]="router.url === '/' ? '' : 'reset-bg'">
    <div class="container header-main" >
        <a href="/" class="header-logo">
            <img src="/images/logo.png" alt="logo" />
        </a>
        <div>
            <ul>
                <li>
                    <a (click)="pushEvent('convert')" [routerLink]="['/convert']" routerLinkActive="is-active-1" href="/convert">Convert</a>
                </li>
                <li>
                    <a (click)="pushEvent('merge')" [routerLink]="['/merge']" routerLinkActive="is-active-2" href="/merge">Merge</a>
                </li>
                <li>
                    <a (click)="pushEvent('compress')" [routerLink]="['/compress']" routerLinkActive="is-active-3" href="/compress">Compress</a>
                </li>
            </ul>
        </div>
      
    </div>
</header>